import React from "react"

const Newsletter = () => {
  return (
    <section className="py-20 md:py-32 lg:py-36 xl:py-48">
      <div className="max-w-screen-lg lg:mx-5 xl:mx-auto">
        <div className="flex flex-col lg:flex-row items-center lg:items-start  lg:justify-around xl:justify-between">
          <h1 className="text-2xl md:text-3xl lg:text-3xl xl:text-4xl pb-10 md:pb-14 lg:pb-0 font-semibold tracking-wide leading-tight">
            Join our newsletter to get
            <br /> exciting offers update.
          </h1>
          <div className="">
            <form
              action="https://formspree.io/f/xleokpvn"
              method="POST"
              className="flex flex-col items-center md:block"
            >
              <input
                type="text"
                name="name"
                placeholder="First name"
                className="w-72 md:w-44 lg:w-44 xl:w-40 py-2 md:py-3 px-6 focus:outline-none border border-gray-400 mb-2 md:mb-0 pl-4 md:mr-2"
              />
              <input
                type="text"
                name="email"
                placeholder="Email address"
                className="w-full md:w-64 lg:w-56 xl:w-64 py-2 md:py-3 px-6 focus:outline-none border border-gray-400 pl-4"
              />
              <button
                type="submit"
                className="w-full bg-black md:flex md:flex-col md:items-center text-white text-sm md:text-base font-medium py-3 px-6 md:py-3 md:px-8 mt-4 md:mt-5 hover:bg-amber-900 transition-all duration-500 ease-linear"
              >
                SUBSCRIBE
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
