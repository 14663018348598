import React from "react"

const FaqCollection = ({ faq }) => {
  return (
    <section className="">
      <div className="max-w-screen-xl mx-auto w-11/12 md:w-4/5 lg:w-2/3 xl:w-1/2">
        <div className="mb-5 shadow-md py-6 px-6 border-l-4 border-amber-500">
          <h1 className="text-lg md:text-xl lg:text-2xl font-medium pb-3 text-amber-800">
            {faq.question}
          </h1>
          <p className="text-sm lg:text-base font-light">{faq.answer.answer}</p>
        </div>
      </div>
    </section>
  )
}

export default FaqCollection
